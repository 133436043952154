<template>
  <b-container
    class="mb-5"
    fluid
  />
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'AdminDashboard',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  mounted () {
    core.index()
  }
}
</script>
